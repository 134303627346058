import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Accordion, AccordionButton, AccordionItem, AccordionPanel } from '@reach/accordion';
import '@reach/accordion/styles.css';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';
import longArrow from '../../images/long-right-arrow.svg';
import { padding } from '../layout/GlobalStyles';
import { PlainBtn } from './../Buttons/Plain';
import { h1 } from './../Headings';
import { py32 } from './../layout/GlobalStyles';

const Section = styled.section`
    position: relative;

    ::before {
        content: '';
        position: absolute;
        left: -100vw;
        right: -100vw;
        top: 0;
        bottom: 0;
        background-color: ${({ theme }) => theme.white};
        z-index: -1;
    }
`;

const Heading = styled.div`
    padding-top: 48px;

    > h4 {
        ${h1};
        margin: 0 16px 0;
        max-width: 775px;
    }

    > p {
        margin: 8px 16px 32px;
        font-weight: 700;
        max-width: 545px;
    }

    @media (min-width: 1024px) {
        padding-top: 94px;

        > h4 {
            margin-left: 32px;
        }

        > p {
            margin-left: 32px;
            margin-bottom: 72px;
        }
    }

    @media (min-width: 1280px) {
        width: 83.33vw;
        max-width: 1600px;
        margin-left: auto;

        > h4 {
            margin: 0;
        }

        > p {
            margin-left: 0;
        }
    }
`;

const Wrapper = styled.div`
    padding-top: 482px;
    position: relative;

    @media (min-width: 1280px) {
        padding-top: 0;
    }
`;

const StyledAccordion = styled(Accordion)`
    ${padding};
    ${py32}
    padding: 16px;
    border-top-left-radius: 32px;
    background: ${({ theme }) => theme.kindaWhite};

    @media (min-width: 1280px) {
        border-top-left-radius: 64px;
        position: relative;
        width: 83.33vw;
        max-width: 1600px;
        margin-left: auto;
    }

    @media (min-width: 1440px) {
        border-top-left-radius: 128px;
        padding: 96px;
    }

    @media (min-width: 1600px) {
        padding: 128px;
    }
`;

const StepsCount = styled.p`
    color: ${({ theme }) => theme.white};
    ${h1};
    margin: 0 0 64px;
    display: inline-block;
`;

const StyledBtn = styled(AccordionButton)`
    font-size: ${({ active }) => (active ? '1.31rem' : '1rem')};
    color: ${({ theme, active }) => (active ? theme.darkTeal : '#bdbdbd')};
    font-weight: ${({ active }) => (active ? 700 : 400)};
    background: transparent;
    border: none;
    padding: 0;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    transition-property: color, font-weight, font-size;
    text-align: left;

    :hover,
    :focus {
        color: ${({ theme, active }) => (active ? theme.darkTeal : theme.kindaBlack)};
    }

    @media (min-width: 1280px) {
        font-size: 1.5rem;
    }

    @media (min-width: 1440px) {
        position: relative;

        ::before {
            content: url(${longArrow});
            position: absolute;
            left: -96px;
            top: -5px;
            opacity: ${({ active }) => (active ? 1 : 0)};
            transform: ${({ active }) =>
                active ? 'translateX(0) scaleX(1)' : 'translateX(-10px) scaleX(0.5)'};
            transition: transform 0.3s ease-in-out, opacity 0.2s ease-in;
            transform-origin: left;
        }
    }

    @media (min-width: 1600px) {
        ::before {
            left: -116px;
        }
    }
`;

const Description = styled.p`
    @keyframes fadeIn {
        0% {
            opacity: 0;
            transform: translateY(10px);
        }
        100% {
            opacity: 1;
            transform: translateY(0);
        }
    }

    animation: fadeIn 0.3s ease-in-out;

    @media (min-width: 1280px) {
        margin-bottom: 64px;
    }
`;

const Progress = styled.div`
    display: flex;
    flex-direction: column;
    position: absolute;
    right: 16px;
    top: 498px;

    @media (min-width: 1280px) {
        right: 32px;
        top: 64px;
    }

    @media (min-width: 1440px) {
        right: 60px;
    }

    @media (min-width: 1800px) {
        top: 96px;
    }

    @media (min-width: 1900px) {
        top: 128px;
    }
`;

const Bullet = styled(PlainBtn)`
    width: 16px;
    height: ${({ active }) => (active ? '64px' : '16px')};
    background: ${({ active, theme }) => (active ? theme.darkTeal : '#C4C4C4')};
    border-radius: 16px;
    margin-bottom: 16px;
`;

const StyledAccordionItem = styled(AccordionItem)`
    @media (min-width: 1280px) {
        max-width: 530px;
    }

    @media (min-width: 1600px) {
        margin-left: 10px;
        max-width: 660px;
    }
`;

export default function HomeSteps() {
    const data = useStaticQuery(graphql`
        query homeStepsImages {
            allFile(
                filter: { relativeDirectory: { eq: "steps" } }
                sort: { order: ASC, fields: name }
            ) {
                nodes {
                    childImageSharp {
                        gatsbyImageData(
                            width: 479
                            height: 719
                            breakpoints: [300, 400, 479]
                            sizes: "(min-width: 768px) 479px, 300px"
                        )
                    }
                }
            }
        }
    `);
    const images = data.allFile.nodes.map(node => getImage(node));

    let steps = [
        {
            heading: 'Initial exam and consultation',
            description: 'Your first visit, where we examine and determine a course of action.',
        },
        {
            heading: 'Diagnostic records and imaging',
            description: 'We use modern equipment for an accurate image.',
        },
        {
            heading: 'Full banding and bonding',
            description: 'just like traditional braces.',
        },
        {
            heading: 'Periodic adjustments',
            description: 'We Refine your smile',
        },
        {
            heading: 'Elastics wear to correct your bite',
            description: 'to improve your upper and lower jaw (Bite)',
        },
        {
            heading: 'Finishing archwires ',
            description: 'We are almost done with the process.',
        },
        {
            heading: 'Braces removal!',
            description: 'A New smile and a new you, and it’s all sunshines from now on.',
        },
    ];

    steps = images.map((image, i) => ({
        image,
        ...steps[i],
    }));

    const [step, setStep] = React.useState(0);

    const handleSteps = e => {
        setStep(Number(e.target.getAttribute('data-step')));
    };
    return (
        <Section>
            <Heading>
                <h4>Orthodontics made easy.</h4>
                <p>Braces and InVisalign made easy.</p>
            </Heading>
            <Wrapper>
                <StyledAccordion index={step} onChange={value => setStep(value)}>
                    <StepsCount
                        onClick={() => setStep(step => (step < steps.length - 1 ? step + 1 : 0))}
                    >
                        {step + 1}/{steps.length}
                    </StepsCount>

                    <Progress>
                        {steps.map((_, i) => (
                            <Bullet
                                key={i}
                                active={i === step ? 1 : 0}
                                data-step={i}
                                onClick={handleSteps}
                                aria-label="change slides"
                            />
                        ))}
                    </Progress>

                    {steps.map((accordion, i) => (
                        <StyledAccordionItem key={i}>
                            <h3
                                css={css`
                                    margin: 16px 0;
                                `}
                            >
                                <StyledBtn active={i === step ? 1 : 0} data-step={i === step}>
                                    {accordion.heading}
                                </StyledBtn>
                            </h3>
                            <AccordionPanel>
                                <Description>{accordion.description}</Description>
                                <GatsbyImage
                                    image={accordion.image}
                                    alt={accordion.heading}
                                    css={css`
                                        box-shadow: 0px 32px 64px rgba(33, 163, 185, 0.3);
                                        border-radius: 64px;
                                        position: absolute;
                                        top: 0;
                                        left: 50%;
                                        transform: translateX(-50%);
                                        width: 300px;
                                        max-width: 90vw;
                                        animation: slide-down 0.3s ease-in-out;

                                        @keyframes slide-down {
                                            0% {
                                                opacity: 0;
                                                transform: translateX(-50%) translateY(10px);
                                            }
                                            100% {
                                                opacity: 1;
                                                transform: translateX(-50%) translateY(0);
                                            }
                                        }

                                        @media (min-width: 768px) {
                                            width: 379px;
                                        }

                                        @media (min-width: 1280px) {
                                            width: 350px;
                                            top: -180px;
                                            left: unset;
                                            right: 96px;
                                            transform: translateX(0);

                                            @keyframes slide-down {
                                                0% {
                                                    opacity: 0;
                                                    transform: translateY(10px);
                                                }
                                                100% {
                                                    opacity: 1;
                                                    transform: translateY(0);
                                                }
                                            }
                                        }

                                        @media (min-width: 1440px) {
                                            right: 148px;
                                        }

                                        @media (min-width: 1600px) {
                                            width: 400px;
                                        }

                                        @media (min-width: 1800px) {
                                            right: 175px;
                                        }

                                        @media (min-width: 1900px) {
                                            right: 248px;
                                            width: 479px;
                                        }
                                    `}
                                />
                            </AccordionPanel>
                        </StyledAccordionItem>
                    ))}
                </StyledAccordion>
            </Wrapper>
        </Section>
    );
}
